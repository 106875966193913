import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { keyframes } from "@mui/system";

import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet";

// import aboutImage from "../../assets/images/about.jpg";
// import code from "../../assets/images/code.png";

import ReactGA from 'react-ga';

const Home = () => {
  const content = `Hi! I am Somoy. Machine Learning x Software Engineer and a Potato 🥔`;
  const speed = 40;
  const [displayedContent, setDisplayedContent] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    /*Create a new setInterval and store its id*/
    const animKey = setInterval(() => {
      setIndex((index) => {
        if (index >= content.length - 1) {
          clearInterval(animKey);
          return index;
        }
        return index + 1;
      });
    }, speed);
  }, [content.length]);

  useEffect(() => {
    setDisplayedContent(
      (displayedContent) => displayedContent + content[index]
    );
  }, [content, index]);

  const blink = keyframes`
   from{
       opacity: 0%;
    }
    to{
        opacity: 100%;
     }
`;
  const type = (
    <Box
      component="span"
      sx={{
        display: "inline-block",
        mx: "2px",
        transform: "scale(0.8)",
        animation: `${blink} 0.5s infinite`,
      }}
    >
      |
    </Box>
  );

  return (
    <Grid container>
      <Helmet>
        <title>Somoy's App</title>
      </Helmet>
      <Grid item md={12} xs={12}>
        <Card sx={{ minHeight: "39.2vw" }}>
          <CardContent>
            <Box sx={{ minWidth: "50vw", minHeight: "30vh" }}>
              <Typography variant="h4" color={"#2B2D42"}>
                {displayedContent}
                {type}
              </Typography>
            </Box>
          </CardContent>
          <CardActions>
            <Stack
              spacing={2}
              sx={{ align: "center", justifyContent: "center", width: "100%"}}
              direction={"row"}
            >
              <Button component={RouterLink} to="/about" variant="outlined"  color='secondary' sx={{textAlign: 'center'}} onClick={
                ()=> {
                  ReactGA.event({
                    category: 'Button',
                    action: 'Click',
                    label: 'About Me Button Clicked.'
                  });}
                }>
                    <Typography
                      variant="h5"
                      color={"#2B2D42"}
                    >About Me{" "}
                    </Typography>
              </Button>
              <Button component={RouterLink} to="/portfolio" variant="outlined" color='secondary' sx={{textAlign: 'center'}}  onClick={
                ()=> {
                  ReactGA.event({
                    category: 'Button',
                    action: 'Click',
                    label: 'Portfolio Button Clicked.'
                  });}
              }>
                <Typography variant="h5" color={"#2B2D42"}>
                  My Projects{" "}
                </Typography>
              </Button>
            </Stack>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Home;
