import sohopathi from "../assets/images/work_icons/sohopathi.webp";
import bracu from "../assets/images/work_icons/bracu.png";
import bjit from "../assets/images/work_icons/bjit.jpg";
import ediss from "../assets/images/education_icons/ediss.png";

const educationDetails = [
  {
    id: "1e",
    title: "Erasmus Mundus Joint Master Degree in Data Intensive Software Systems",
    shortTitle: "M.S in Data Science and Software Engineering",
    institution: "Åbo Akademi, University of Turku, and Three other European universities",
    instituteShort: "ÅA and UTU",
    institutionURL1: "https://www.master-ediss.eu",
    url1Alias: "EDISS",
    location: "Finland, Sweden, Italy, and Spain",
    startDate: "2023-08-01",
    endDate: "2025-09-01",
    CGPA: "NA",
    logo: ediss,
  },
  {
    id: "2e",
    title: "Bachelor of Science in Computer Science and Engineering",
    shortTitle: "B.S in CSE",
    institution: "BRAC University",
    instituteShort: "BRACU",
    institutionURL1: "https://www.bracu.ac.bd/",
    url1Alias: "BRACU",
    location: "Dhaka, Bangladesh",
    startDate: "2018-02-01",
    endDate: "2022-02-20",
    CGPA: "3.95 out of 4.00",
    logo: bracu,
  },
  // {
  //   id: '3e',
  //   title: "Secondary Education",
  //   shortTitle: "SSC",
  //   institution: "Bir Shreshtha Noor Mohammad Public School and College",
  //   instituteShort: "BNMPC",
  //   location: "Dhaka, Bangladesh",
  //   startDate: "2012-01-31",
  //   endDate: "2014-01-31",
  //   CGPA: "5.00 out of 5.00",
  // },
];

const workDetails = [
  {
    id: "1w",
    title: "Software Engineer",
    shortTitle: "SwEng",
    company: "BJIT",
    companyShort: "BJIT",
    companyUrl1: "https://bjitgroup.com/",
    url1Alias: "BJIT",
    startDate: "2022-05-16",
    endDate: "2023-06-01",
    location: "Dhaka, Bangladesh",
    logo: bjit,
    description: ""
  },
  {
    id: "2w",
    title: "Software Engineer Intern",
    shortTitle: "SwEng",
    company: "MainframeLabs Ltd. (Sohopathi)",
    companyUrl1: "https://www.sohopathi.io/",
    url1Alias: "Sohopathi",
    companyUrl2: "https://mainframelabs.ltd/",
    url2Alias: "MainframeLabs",
    startDate: "2021-12-11",
    endDate: "2022-04-11",
    logo: sohopathi,
    location: "Dhaka, Bangladesh",
    description:
      " Used ChakraUI and React Typescript to develop dynamic, easily re-usable, well-structured components for different projects  Worked with MaterialUI , ReactJS, Formik, NextJS, API- spec to develop and solve different segments of the company’s core project. ( sohopathi.io )",
  },
  {
    id: "3w",
    title: "Adjunct Lecturer",
    shortTitle: "Lecturer",
    company: "BRAC University",
    companyUrl1: "https://www.bracu.ac.bd/",
    url1Alias: "BRACU",
    startDate: "2022-06-06",
    endDate: "2023-01-31",
    logo: bracu,
    location: "Dhaka, Bangladesh",
    description:
      "Taught Basic Algorithms, Object Oriented Programming, and Digital Logic Design concepts to the university’s undergrad students.",
  },
];

export { educationDetails, workDetails };
